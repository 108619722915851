var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.imgProfile,"text":_vm.avatarText(_vm.user.firstName),"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.user.role))),"size":"90px","rounded":""}})]},proxy:true}],null,true)},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.userData.firstName + " " + _vm.userData.lastName)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"})]),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email","readonly":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"Title"}},[_c('v-select',{attrs:{"options":_vm.titleCombo,"label":"title","clearable":false,"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"First Name","id":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName"}},[_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Contact Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Contact Number","label-for":"Contact-Number"}},[_c('b-form-input',{attrs:{"id":"contact-number","state":errors.length > 0 ? false : null},model:{value:(_vm.user.contactNumber),callback:function ($$v) {_vm.$set(_vm.user, "contactNumber", $$v)},expression:"user.contactNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(_vm.currentMove['verifiedDetails'])?_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 buttons-edit",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.submitChanges('Modifiying User Profile')}}},[_c('span',[_vm._v("Save Changes")])])],1):_vm._e()]}}])}),(!this.lastMove.verifiedDetails && _vm.submissionDate > _vm.dayCondition)?_c('validation-observer',{ref:"formModifyLastMove",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h4',{staticClass:"mt-3 mb-1"},[_vm._v("My move on "+_vm._s(_vm.lastMove.moveDetails["moveInDate"]))]),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"newMoveFromAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Previous Address","label-for":"newMoveFromAddress"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.newMoveFromAddress),callback:function ($$v) {_vm.newMoveFromAddress=$$v},expression:"newMoveFromAddress"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"newMoveFromPostcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Previous Address Postcode","label-for":"newMoveFromPostcode"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.newMoveFromPostcode),callback:function ($$v) {_vm.newMoveFromPostcode=$$v},expression:"newMoveFromPostcode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Move Out Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Move Out Date")]),_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.newMoveOutDate),callback:function ($$v) {_vm.newMoveOutDate=$$v},expression:"newMoveOutDate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"newMoveToAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Address","label-for":"newMoveToAddress"}},[_c('b-form-input',{model:{value:(_vm.newMoveToAddress),callback:function ($$v) {_vm.newMoveToAddress=$$v},expression:"newMoveToAddress"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"newMoveToPostcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Address Postcode","label-for":"newMoveToPostcode"}},[_c('b-form-input',{model:{value:(_vm.newMoveToPostcode),callback:function ($$v) {_vm.newMoveToPostcode=$$v},expression:"newMoveToPostcode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Move In Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Move In Date")]),_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.newMoveInDate),callback:function ($$v) {_vm.newMoveInDate=$$v},expression:"newMoveInDate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 buttons-edit",attrs:{"variant":"primary","disabled":invalid || _vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.submitChanges()}}},[_c('span',{staticClass:"mr-1"},[_vm._v("Confirm my Details")]),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}}):_vm._e()])],1)]}}],null,false,2936525509)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }